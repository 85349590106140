import { Listbox } from "@headlessui/react";
import { CheckCircleIcon, ExclamationCircleIcon, ExclamationTriangleIcon, PlusIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Form, Formik } from "formik";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next"
import Select from 'react-select'
import useUserEmails from "../../swr/getUserEmails";
import Error from "../Error";
// import Loading from "../Loading";
import EmailComponent from "./EmailComponent";
import NewEmailForm from "./newEmailForm";
import Loading from "../../components/loading";
import NoticeBar from "../../components/NoticeBar";



const EmailAddresses = () =>{

    const [newEmail , setNewEmail] = useState(false)
    const {emails, isLoading, isError} = useUserEmails()
    const {t} = useTranslation();
    const isMobile = window.innerWidth < 768;
    return(
        !newEmail?
        isLoading?
        <Loading />
        :isError?
            <Error/>
        :<div className="space-y-6 md:col-span-9 md:px-0">
            <div className={classNames("bg-white pt-6 overflow-hidden ", {"rounded-[15px] shadow-[0_3px_5px_0_rgba(9,30,66,0.10)] ":!isMobile})}>
                <div className="px-4 sm:px-6">
                    <h2 className="text-lg font-medium leading-6 text-gray-900">
                        {t('identity.profile.EmailAddresses')}
                    </h2>
                </div>
                <div className="mt-6 flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden border-t border-gray-200">
                                <div className="p-6 grid grid-cols-1 gap-y-6">
                                    <NoticeBar text={t("identity.emailAddresses.notPublicEmails")} />
                                    {emails && !_.isEmpty(emails)?
                                    <>
                                        <p className="text-md font-medium leading-6 text-gray-900">
                                            {t('identity.emailAddresses.EmailYouveAdded')}
                                        </p>
                                        
                                        <div className="grid grid-cols-1 gap-y-0.5">
                                            <p className="text-sm font-medium">
                                                {t('identity.emailAddresses.primaryEmail')}
                                            </p>
                                            {emails && !_.isEmpty(emails.filter(e => e.isPrimary))?
                                                emails.filter(e => e.isPrimary).map(e => <EmailComponent key={e.id} email={e}/>)
                                                :
                                                <p className="text-lg pl-1 text-gray-400">
                                                    -
                                                </p>
                                            }
                                        </div>

                                        <div className="grid grid-cols-1 gap-y-0.5">
                                            <p className="text-sm font-medium">
                                                {t('identity.emailAddresses.otherEmails')}
                                            </p>
                                            {emails?.filter(e => !e.isPrimary)?.map(e => <EmailComponent key={e.id} email={e}/>)}
                                        </div>
                                    </>
                                    :null}
                                    

                                    <button
                                        onClick={() => setNewEmail(true)}
                                        type="button"
                                        className="inline-flex w-fit items-center gap-x-2 rounded-md bg-indigo-600 py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    >
                                        <PlusIcon className="-ml-0.5 h-5 w-5 text-white" />
                                       {t('identity.emailAddresses.AddEmailAddress')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        :<NewEmailForm cancelAction={() => setNewEmail(false)}/>
    )
}
export default EmailAddresses