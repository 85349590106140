import classNames from "classnames";
import { Form, Formik } from "formik";
import _, { values } from "lodash";
import { Fragment, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "react-step-progress-bar/styles.css";
import { ProgressBar } from "react-step-progress-bar";
import { CalculateAlphanumericPasswordStrengthLevel } from "../PasswordStrengthUtility";

type propType = {
  setStep: Function;
  setForm: Function;
  form: any;
};

const CreatePassword = (props: propType) => {
  const { t } = useTranslation("identity.");
  const [showPassword, setShowPassword] = useState(false);
  const [percent,setPercent] = useState(0)

  const errorTexts = [
    "At least 12 characters","Contains a number and symbol","Uppercase character eg. A. B. C"
  ]
  const [errorsData, setErrorsData] = useState([0,0,0]);

console.log("errorsData",errorsData)

  const onSubmit = async (values) => {
    if(values.password && percent>=7 ){
      props.setForm({ ...props.form, password: values.password });
      props.setStep(4)
    }
  
  };

  const checkValidate = (values) => {
    if (!values.password || values.password === "") {
      setPercent(0)
    }else{
      var score = CalculateAlphanumericPasswordStrengthLevel(values.password)
      setPercent(score)
      // if (values.password.length > 0 && values.password.length < 12) {
      //   updatedErrors[0]=1
      // }else{
      //   updatedErrors[0] =2
      // }
      // if (/\d/.test(values.password) && /[!@#$%^&*]/.test(values.password)) {
      //   updatedErrors[1] = 2
        
      // }else{
      //   updatedErrors[1] = 1
      // }
      // if (/[A-Z]/.test(values.password)) {
      //   updatedErrors[2] = 2
      // }else{
      //   updatedErrors[2] = 1
      // }
    }
  };

  return (
    <Fragment>
      <h1 className="text-xl normalDoubleBottomMargin text-gray-600">
        {t("identity.src.components.login.CreatePassword.CreateAPassword")}
      </h1>
      <h2 className="text-l normalDoubleBottomMargin text-gray-600">
        {props.form.email}
      </h2>

      <div onClick={() => props.setStep(1)}>
        <a className="cursor-pointer underline inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800">
          {t("identity.src.components.login.CreatePassword.UseADifferentEmail")}
        </a>
      </div>
      <Formik onSubmit={onSubmit} initialValues={{}} validate={checkValidate}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form
            onSubmit={handleSubmit}
            className="organizer fln m0a grid-6 grid-t-12"
          >
            <div className="flex flex-col gap-y-4 place-content-center">
              <div>
                <div className="relative">
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    <svg
                      fill="none"
                      onClick={() => setShowPassword(!showPassword)}
                      className={classNames("h-3 text-gray-700", {
                        hidden: !showPassword,
                        block: showPassword,
                      })}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                      ></path>
                    </svg>

                    <svg
                      fill="none"
                      onClick={() => setShowPassword(!showPassword)}
                      className={classNames("h-3 text-gray-700", {
                        hidden: showPassword,
                        block: !showPassword,
                      })}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path
                        fill="currentColor"
                        d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                      ></path>
                    </svg>
                  </div>
                  <input
                    value={values.password}
                    onChange={handleChange}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    id="email-address-icon"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Create Password"
                  />
                </div>
                <>

                
                  <div className="flex flex-col space-y-2 mt-3">
                    <ProgressBar
                      percent={percent*10}
                      height="4px"
                      filledBackground={
                        percent >= 0 && percent < 3? "#DC3545" : 
                        percent >= 3 && percent < 7 ? "#FC8A20" : 
                        "#00917C"
                      }
                    />
                    <div className="pt-2 flex flex-col space-y-2">
                    {errorTexts.map((text , index) => (
                      <>
                        <div className="flex flex-row space-x-1 items-center">
                          <span className={`  ${
                              errorsData[index] == 0
                                ? "icon-Ellipse-2840-Stroke text-[#828A96]"
                                : errorsData[index] == 1
                                ? "icon-wrong text-[#DC3545]"
                                : "icon-valid text-[#00917C]"
                            } `}></span>
                          <div
                            className={` ${
                              errorsData[index] == 0
                                ? "text-[#828A96]"
                                : errorsData[index] == 1
                                ? "text-[#DC3545]"
                                : "text-[#00917C]"
                            } text-sm font-[400]`}
                          >
                            {text}
                          </div>
                        </div>
                      </>
                    ))}
                    </div>
                  </div>
                </>
              </div>

              <button
                disabled={percent<7}
               type="submit"
                className="cursor-pointer text-white bg-[#1A5DBC] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center block  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {t(
                  "identity.src.components.login.CreatePassword.CreateAccount"
                )}
                <svg
                  className="float-right flex w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
              <p className="text-xs normalDoubleBottomMargin text-gray-600 space-x-1">
                  <span>
                      {t("identity.src.components.login.CreatePassword.ByCreatingAnAcount,YouAgreeToPeopleAnalyticsAnd")}
                  </span>
                  
                  <a  target="_blank" href={process.env.REACT_APP_PUBLIC_URL+"/policies/Terms%20of%20Use"} className="cursor-pointer text-xs text-bold underline inline-block align-baseline text-green-700 hover:text-green-800">
                      {t("identity.src.components.login.CreatePassword.termsofUse")}
                  </a>
                  <span>
                      {t('identity.src.components.login.CreatePassword.and')}
                  </span>
                  <a  target="_blank" href={process.env.REACT_APP_PUBLIC_URL+"/policies/Privacy%20Policy"} className="cursor-pointer text-xs text-bold underline inline-block align-baseline text-green-700 hover:text-green-800">
                      {t("identity.src.components.login.CreatePassword.PrivacyNotice")}.
                  </a>
                  
              </p>
              
              <p className="text-xs normalDoubleBottomMargin text-gray-400">
                {t(
                  "identity.src.components.login.CreatePassword.AllreadyHaveAnAcount?"
                )}
                <p
                  onClick={() => props.setStep(1)}
                  className="cursor-pointer text-xs text-bold underline inline-block align-baseline text-blue-700 hover:text-blue-800"
                >
                  {t("identity.src.components.login.CreatePassword.SignIn")}
                </p>
              </p>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default CreatePassword;
