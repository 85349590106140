import useSWR from "swr";
import { axiosInstance } from "../services/AxiosInstance";
import {InviteClient } from "../swagger";

function useUserInfoFromInviteToken(token) {
    var client = new InviteClient(undefined, axiosInstance);
    const {data, error} = useSWR(`invite.getUserInfoFromInviteToken${token}`, () => client.getUserInfoFromInviteToken(token))
    return {
        users: data,
        isLoadingUsersApi: !error && !data,
        isErrorUsersApi: error
      }
}

export default useUserInfoFromInviteToken;