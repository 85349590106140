import React, { Fragment, useEffect, useState } from "react";
import _ from "lodash";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import {
  AccountClient,
  ResetPasswordCommand,
  UserPhoneNumber,
} from "../../../swagger";
import { axiosInstance } from "../../../services/AxiosInstance";
import PasswordComponent from "../../../components/PasswordComponent";

type propType ={
  isEmail:boolean,
  email:string,
  userPhoneNumber:any,
  token:string
}
const ChangePass = (props: propType) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation("identity.");
  const [waitForRedirect , setWaitForRedirect] = useState(false)
  

  const handleSubmit = async (values) => {
    setLoading(true);
    const client = new AccountClient(undefined, axiosInstance);
    const authResult = new URLSearchParams(window.location.search);
    const redirectUrl = authResult.get("returnUrl");
    var command = null
    if(props.isEmail){
      command = {
        email: props.email,
        userPhoneNumber: null,
        isEmail: true,
        token: props.token,
        password: values.password,
      } as ResetPasswordCommand;
    }else{
      command = {
        email: undefined,
        userPhoneNumber: {
          phoneNumberType: 1,
          phoneCountryId: props.userPhoneNumber.phoneCode
            ? props.userPhoneNumber.phoneCode.icon
            : null,
          phoneNumber: props.userPhoneNumber.phoneNumber,
        } as UserPhoneNumber,
        isEmail: false,
        token: props.token,
        password: values.password,
      } as ResetPasswordCommand;
    }

    

    
    try {
      const res = await client.resetPassword(command, redirectUrl);
      if (res?.succeeded) {
        toast.success(t("identity.profile.success"));
        setLoading(false);
        setWaitForRedirect(true)
        window.location.replace('/Account/Login')
      } else {
        setLoading(false);
        toast.error(_.first(res.errors) as any);
      }
    } catch (error) {
      setLoading(false);
      toast.error(t("identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"));
    }
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={true}></Toaster>
      <h1 className="text-xl normalDoubleBottomMargin text-gray-600">
        {t("identity.inviteUser.createPassword")}
      </h1>
      <PasswordComponent
        getCurrentPassword={false}
        handleSubmit={handleSubmit}
        loading={loading}
        waitForRedirect={waitForRedirect}
      />
    </>
  );
};

export default ChangePass;
