import { useTranslation } from "react-i18next";
import { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { AccountClient, EnableTwoFactorCommand, TwoFactorProvider } from "../../swagger";
import { axiosInstance } from "../../services/AxiosInstance";
import toast from "react-hot-toast";
import CheckPassword from "./checkPassword";

type propType = {
  setStep:(step:number) => void,
  setQrcode:(code:string) => void,
  nextStep:number,
  hasPassword:boolean,
  twoFactorProvider:TwoFactorProvider,
  setTwoFactorProvider:(provider:TwoFactorProvider)=> void
  twoFactorEnabled:boolean
};

const TwoStepVerificationSetUpForm = (props: propType) => {
  const { t } = useTranslation();
  const notificationMethods = [
    { id: "app", title: t("identity.profile.AuthenticatorApp")},
    { id: "phone", title: t("identity.profile.phoneNumber(SMS)")},
  ];
  const prevMethod = props.twoFactorEnabled?props.twoFactorProvider == TwoFactorProvider.PhoneNumber?notificationMethods[1].id:notificationMethods[0].id:"";
  const [selectedMethod, setSelectedMethod] = useState<string>(prevMethod);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const handleClick = () => {
    setResult(null)
    if(props.twoFactorEnabled && prevMethod == selectedMethod){
      toast.error(t("identity.profile.thisAuthenticationMethodIsAlreadySelected"))
    }else if (selectedMethod == "app") {
      props.setTwoFactorProvider(TwoFactorProvider.GoogleAuthenticator)
      setOpenModal(true);
    } else if (selectedMethod == "phone") {
      props.setTwoFactorProvider(TwoFactorProvider.PhoneNumber)
      props.setStep(3);
    }
  };


  const handleSubmit = async (password:string) => {
    const client = new AccountClient(undefined, axiosInstance);
    setLoading(true);
    const command = {
      user: undefined,
      provider:TwoFactorProvider.GoogleAuthenticator,
      phoneNumberId: 0,
      password: password,
      isEnable: true,
      isResend: false,
    } as EnableTwoFactorCommand;

    try {
      var res = await client.enableTwoFactorAuthentication(command);
      if (res && res.succeeded ) {
        // toast.success(t("identity.profile.updated"));
        props.setQrcode(res?.formattedKey);
        setLoading(false);
        setOpenModal(false);
        props.setStep(props.nextStep);
      } else {
        toast.error(_.first(res.errors));
        setLoading(false);
        setResult(res.errors)
      }
    } catch (error) {
      toast.error(t("identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"))
      setLoading(false);
    }
  };


  return (
    <>
      <div className="space-y-6 sm:px-6 md:col-span-9 md:px-0">
        <div className="bg-white pt-6 shadow-[0_3px_5px_0_rgba(9,30,66,0.10)] rounded-[15px] overflow-hidden ">
          <div className="px-4 sm:px-6">
            <h2 className="text-lg font-[600] leading-6 text-gray-900">
              {t("identity.profile.TwoStepVerification")}
            </h2>
          </div>

          <div className="mt-6 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border-t border-gray-200">
                  <div className="p-6 !pb-9 grid grid-cols-1 gap-y-6">
                    <p className="text-base font-[600] leading-6 text-gray-900">
                      {t(
                        "identity.profile.SecureYourLinkedInAccountWithTwoStepVerification"
                      )}
                    </p>

                    <div className="flex flex-col space-y-2">
                      <fieldset className="mt-4">
                        <div className="space-y-4">
                          {notificationMethods.map((notificationMethod) => (
                            <div
                              key={notificationMethod.id}
                              className="flex items-center"
                            >
                              <input
                                id={notificationMethod.id}
                                name="notification-method"
                                type="radio"
                                checked={selectedMethod == notificationMethod.id}
                                defaultChecked={
                                  notificationMethod.id === "email"
                                }
                                className="h-4 w-4 border-gray-300 text-[#0052CC] focus:ring-[#0052cc88]"
                                onChange={() =>
                                  setSelectedMethod(notificationMethod.id)
                                }
                              />
                              <label
                                htmlFor={notificationMethod.id}
                                className="ml-3 block text-[14px] font-[600] text-[#091E42]"
                              >
                                {notificationMethod.title}
                              </label>
                            </div>
                          ))}
                        </div>
                      </fieldset>
                    </div>

                    <div className="flex flex-row space-x-3">
                      <button
                        onClick={() => props.setStep(1)}
                        className="text-[#1A5DBC] text-[14px] font-[600] cursor-pointer py-2 px-5 border-[#1A5DBC] border-[1px] rounded-md"
                      >
                        {t("identity.basicProfileInfo.Back")}
                      </button>
                      <button
                        onClick={handleClick}
                        className={`w-fit h-fit ${
                          selectedMethod.length == 0
                            ? "cursor-not-allowed"
                            : "cursor-pointer"
                        } py-2 px-10 inline-flex justify-center rounded-md bg-[#1A5DBC] text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500`}
                      >
                        {t("identity.basicProfileInfo.Continue")}
                      </button>
                    </div>

                    <div className="text-[14px] font-[300] leading-6 text-[#828A96]">
                      {t("identity.profile.NoteTurningThisFeature")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CheckPassword
        setOpenModal={setOpenModal}
        openModal={openModal}
        handleSubmit={handleSubmit}
        loading={loading}
        hasPassword={props.hasPassword}
        result ={result}
      />
    </>
  );
};
export default TwoStepVerificationSetUpForm;
