import useSWR from "swr";
import { axiosInstance } from "../services/AxiosInstance";
import {CountryClient} from "../swagger";

function useGetCountries() {
    var client = new CountryClient(null, axiosInstance);
    const {data, error} = useSWR(`country.getAllCountries`, () => client.getAllCountries())
    return {
        countries: data,
        isLoading: !error && !data,
        isError: error
      }
}

export default useGetCountries;