import { Field, Formik, Form } from "formik";
import React, { useState, useEffect, Fragment, useRef } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../services/AxiosInstance";
import { AccountClient, IsEmailExistQuery } from "../../swagger";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { b64DecodeUnicode } from "../convert";
import Turnstile from "react-turnstile";

type propType = {
  setStep: Function;
  form: any;
  setForm: Function;
  redirectUrl: string;
  // setIs2FAEnabled : any,
};


const EnterEmail = (props: propType) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const [isMobile, setIsMobile] = useState(false);


  useEffect(() => {
    setIsMobile(window.innerWidth < 768);
  }, []);


  useEffect(()=>{
    console.log('value' , value)
  },[value])


  const { t } = useTranslation("identity.");
  const onSubmit = async (values) => {
    setLoading(true);
    const client = new AccountClient(undefined, axiosInstance);
    // if (value.length > 0) {
      try {
        const res = await client.isEmailExist({captchaCode : value , email : values.email} as IsEmailExistQuery);
        console.log("res", res);
        if (!res) {
          toast.error(
            t(
              "identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"
            )
          );
        } else if (res.succeeded) {
          if(res.key == 0){
            props.setForm({ ...props.form, email: values.email });
            setLoading(false);
            props.setStep(3);
          }else if(res.key == 1){
            props.setForm({ ...props.form, email: values.email });
            setLoading(false);
            // props.setIs2FAEnabled(res.is2FAEnabled)
            props.setStep(2);
            // TokenStorage.storeToken(res.data. as string);
            // TokenStorage.storeRefreshToken(res.refresh_token as string);
            // window.location.replace("/");
          }
        } else if (!res.succeeded) {
          toast.error(res.errors.join(', '));
        }
      } catch (error) {
        setLoading(false);
        toast.error(
          t(
            "identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"
          )
        );
        // addToast("Server Error Occured", { appearance: "error" });
      }
    // } else {
    //   toast.error(t("Please complete all fields"));
    // }
    setLoading(false);
  };

  const checkValidate = (values) => {
    let errors = {} as any;
    if (!values.email || values.email == "") {
      errors.email = t(
        "identity.src.components.login.EnterEmail.enterTheEmail"
      );
    } else if (
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(values.email)
    ) {
      errors.email = t(
        "identity.src.components.login.EnterEmail.theEmailIsNotValid"
      );
    }
    return errors;
  };

  const EnternalLogin = async (providerName) => {
    //const client = new AccountClient(undefined, axiosInstance);
    var ret = b64DecodeUnicode(props.redirectUrl);
    window.location.replace(
      "/api/account/provider/" +
        providerName +
        "?returnUrl=/Account/CompletePersonalInfo&url=" +
        ret
    );
    // try {
    //     const res = await client.getProvider(providerName , '/Account/CompletePersonalInfo')
    //     console.log('res' , res)
    //     if(!res){
    //         setLoading(false)
    //         toast.error("something unexpected happened! please try again later")
    //     }
    // } catch (error) {
    //     setLoading(false);
    //     toast.error("something unexpected happened! please try again later")
    // }
  };

  return (
    <Fragment>
      <h1 className="text-xl normalDoubleBottomMargin text-gray-600 ">
        {t(
          "identity.src.components.login.EnterEmail.EnterYourEmailAddressToSignInOrToCreateAnAccount"
        )}
      </h1>
      <button
        onClick={() => EnternalLogin("Google")}
        type="button"
        className="justify-center text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
      >
        <svg
          className="w-6 h-6 mr-2 -ml-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          width="48px"
          height="48px"
        >
          <path
            fill="#FFC107"
            d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
          />
          <path
            fill="#FF3D00"
            d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
          />
          <path
            fill="#4CAF50"
            d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
          />
          <path
            fill="#1976D2"
            d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
          />
        </svg>
        {t("identity.src.components.login.EnterEmail.SignInWithGoogle")}
      </button>
      <button
        onClick={() => EnternalLogin("LinkedIn")}
        type="button"
        className="justify-center text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2"
      >
        <svg
          className="w-6 h-6 mr-2 -ml-1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          width="48px"
          height="48px"
        >
          <path
            fill="#0078d4"
            d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5 V37z"
          />
          <path
            d="M30,37V26.901c0-1.689-0.819-2.698-2.192-2.698c-0.815,0-1.414,0.459-1.779,1.364 c-0.017,0.064-0.041,0.325-0.031,1.114L26,37h-7V18h7v1.061C27.022,18.356,28.275,18,29.738,18c4.547,0,7.261,3.093,7.261,8.274 L37,37H30z M11,37V18h3.457C12.454,18,11,16.528,11,14.499C11,12.472,12.478,11,14.514,11c2.012,0,3.445,1.431,3.486,3.479 C18,16.523,16.521,18,14.485,18H18v19H11z"
            opacity=".05"
          />
          <path
            d="M30.5,36.5v-9.599c0-1.973-1.031-3.198-2.692-3.198c-1.295,0-1.935,0.912-2.243,1.677 c-0.082,0.199-0.071,0.989-0.067,1.326L25.5,36.5h-6v-18h6v1.638c0.795-0.823,2.075-1.638,4.238-1.638 c4.233,0,6.761,2.906,6.761,7.774L36.5,36.5H30.5z M11.5,36.5v-18h6v18H11.5z M14.457,17.5c-1.713,0-2.957-1.262-2.957-3.001 c0-1.738,1.268-2.999,3.014-2.999c1.724,0,2.951,1.229,2.986,2.989c0,1.749-1.268,3.011-3.015,3.011H14.457z"
            opacity=".07"
          />
          <path
            fill="#fff"
            d="M12,19h5v17h-5V19z M14.485,17h-0.028C12.965,17,12,15.888,12,14.499C12,13.08,12.995,12,14.514,12 c1.521,0,2.458,1.08,2.486,2.499C17,15.887,16.035,17,14.485,17z M36,36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698 c-1.501,0-2.313,1.012-2.707,1.99C24.957,25.543,25,26.511,25,27v9h-5V19h5v2.616C25.721,20.5,26.85,19,29.738,19 c3.578,0,6.261,2.25,6.261,7.274L36,36L36,36z"
          />
        </svg>
        {t("identity.src.components.login.EnterEmail.SignInWithLinkedIn")}
      </button>
      <div className="flex items-center py-4">
        <div className="flex-grow h-px bg-gray-400"></div>
        <span className="flex-shrink px-4 font-light">
          {t("identity.src.components.login.EnterEmail.OrSignInWithEmail")}
        </span>
        <div className="flex-grow h-px bg-gray-400"></div>
      </div>
      <Formik
        onSubmit={onSubmit}
        initialValues={{ email: "" }}
        validate={checkValidate}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form
            onSubmit={handleSubmit}
            className="organizer fln m0a grid-6 grid-t-12"
          >
            <div className="flex flex-col gap-y-4 place-content-center">
              <div>
                <div className="relative">
                  <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                    <svg
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                      <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                    </svg>
                  </div>
                  <input
                    name="email"
                    type="email"
                    id="email-address-icon"
                    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                  />
                </div>
                {errors.email ? (
                  // && touched.userLoginEmail
                  <p className="text-red-500 text-xs italic">
                    {errors.email.toString()}
                  </p>
                ) : null}
              </div>

              <Turnstile
                sitekey={process.env.REACT_APP_TURNSTILE}
                onVerify={(token) => setValue(token)}
              />

              <button
                type="submit"
                disabled={isSubmitting}
                //  || value== null}
                className="text-white bg-[#00917c] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center block  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {!loading ? (
                  <Fragment>
                    {t(
                      "identity.src.components.login.EnterEmail.SignInToYourAccount"
                    )}
                    <svg
                      className="float-right flex w-5 h-5 ml-2 -mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </Fragment>
                ) : (
                  <Fragment>
                    <svg
                      role="status"
                      className="inline w-4 h-4 mr-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    {t("identity.src.components.login.EnterEmail.Loading")}
                  </Fragment>
                )}
              </button>

              <a
                target="_blank"
                href={process.env.REACT_APP_PUBLIC_URL + "/policies/Privacy%20Policy"}
                className="underline inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
              >
                {t("identity.src.components.login.EnterEmail.PrivacyNotice")}
              </a>
            </div>
          </Form>
        )}
      </Formik>
      <Link to={"/account/ForgetPassword"}>
        <a className="underline inline-block align-baseline text-sm text-green-700 hover:text-green-800">
          {t("identity.src.components.login.Password.ForgotPassword")}
        </a>
      </Link>
    </Fragment>
  );
};

export default EnterEmail;
