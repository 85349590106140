import { useEffect, useState , useMemo} from "react"
import { useTranslation } from "react-i18next"
import ChangePassword from "./changePass"
import HasAccount from "./hasAccount"
import getUserInfo from '../../swr/getUserInfo'
import { useSearchParams } from "react-router-dom"
import useCheckIfUserLogin from "../../swr/checkIfUserLogin"


const MainPage = (props) =>{
    const {authenticated , isLoading , isError} = useCheckIfUserLogin()
    const [searchParams] = useSearchParams();
    const token = useMemo(() => searchParams.get('token'), [searchParams]);


    useEffect(()=>{
        console.log('authenticated' , isLoading , isError , authenticated)
    },[isLoading , isError , authenticated])


    console.log('token', token)

    return(
        !token || isLoading?
            null
        :
            authenticated?
                <HasAccount token={token}/>
            :
                <ChangePassword token={token}/>
    )

}
export default MainPage;