import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import config from './i18n.js';
import i18next from 'i18next';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const locale=  i18next.language || window.localStorage.i18nextLng;


if (typeof document !== 'undefined') {
    document.dir = locale == 'fa-IR'? 'rtl': 'ltr';
}


root.render(
  <React.StrictMode>
    <I18nextProvider i18n={config}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

