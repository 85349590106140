import { XCircleIcon } from "@heroicons/react/20/solid"
import { useTranslation } from "react-i18next"
import Loading from "./Loading"
import useCheckIfUserLogin from "../swr/checkIfUserLogin"
import {useEffect} from "react"


const Index = () =>{
    const {authenticated , isLoading , isError} = useCheckIfUserLogin()
    
    useEffect(()=>{
        if(isLoading)
            return    
        if(authenticated){
            window.location.replace('Account/Profile')
        }else{
            window.location.replace('/Account/Login')
        }
    },[authenticated])


    return(
        <Loading/> 
    )
    }
export default Index