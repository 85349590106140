import { useTranslation } from "react-i18next";
import _ from "lodash";
import { QRCodeSVG } from "qrcode.react";

type propType = {
  qrcode:string,
  setStep:(step:number) => void
};

const AuthenticatorApp = (props: propType) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="space-y-6 sm:px-6 md:col-span-9 md:px-0">
        <div className="bg-white pt-6 shadow-[0_3px_5px_0_rgba(9,30,66,0.10)] rounded-[15px] overflow-hidden ">
          <div className="px-4 sm:px-6">
            <h2 className="text-lg font-[600] leading-6 text-gray-900">
              {t("identity.profile.TwoStepVerification")}
            </h2>
          </div>

          <div className="mt-6 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border-t border-gray-200">
                  <div className="p-6 !pb-9 grid grid-cols-1 gap-y-6">
                    <p className="text-base font-[600] leading-6 text-gray-900">
                      {t("identity.profile.SetUpAnAuthenticatorApp")}
                    </p>

                    <div className="flex flex-col space-y-3">
                      <p className="text-sm font-[400] leading-6 text-[rgba(26,26,26,0.63)]">
                        {t("identity.profile.InstallMicrosoftAuthenticator")}
                      </p>
                      <p className="text-sm font-[400] leading-6 text-[rgba(26,26,26,0.63)]">
                        {t(
                          "identity.profile.AddYourAccountToTheAuthenticatorApp "
                        )}
                      </p>
                      <p className="text-sm font-[400] leading-6 text-[rgba(26,26,26,0.63)]">
                        {t("identity.profile.UseLinkedInAsTheAccountName")}
                      </p>
                    </div>

                    <div className="flex flex-col space-y-2">
                      <fieldset className="mt-4">
                        <div className="space-y-4">
                          <QRCodeSVG value={props.qrcode} />
                        </div>
                      </fieldset>
                    </div>

                    <div className="flex gap-2 mt-5">
                      <button
                        onClick={() => props.setStep(2)}
                        className="text-[#1A5DBC] text-[14px] font-[600] cursor-pointer py-2 px-5 border-[#1A5DBC] border-[1px] rounded-md"
                      >
                        {t("identity.basicProfileInfo.Back")}
                      </button>
                      <button
                        onClick={() => props.setStep(6)}
                        className="bg-[#1A5DBC] text-white text-[14px] font-[600] cursor-pointer py-2 px-5 border-[#1A5DBC] border-[1px] rounded-md"
                      >
                        {t("identity.basicProfileInfo.Continue")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AuthenticatorApp;
