import { useState, useEffect, ChangeEvent } from 'react';
import axios from 'axios';
import _debounce from 'lodash/debounce';
import useDebounce from './use-debounce';

interface UseUniqueUrlValidationProps {
  uniqueUrl: string;
  onUniqueUrlChange: (newUrl: string) => void;
}

interface UseUniqueUrlValidationResult {
  showError: boolean;
  isUniqueError: string | null;
  showLoading: boolean;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const useUniqueUrlValidation = ({ uniqueUrl, onUniqueUrlChange }: UseUniqueUrlValidationProps): UseUniqueUrlValidationResult => {
  const [showError, setShowError] = useState(false);
  const [isUniqueError, setIsUniqueError] = useState<string | null>(null);
  const [showLoading, setShowLoading] = useState(false);



  const debounceSearch = useDebounce(uniqueUrl, 200);

  const checkIsUniqueUrl = async (url: string) => {
    setShowLoading(true);
    try {
      const result = await axios.get(process.env.REACT_APP_ADMIN_API_URL + "/api/checking/UrlIsUnique", {
        params: { url, entityType: 1 },
      });

      if (!result || result.status !== 200 || !result.data || !result.data.succeeded) {
        setIsUniqueError(result.data.errors[0]);
        setShowLoading(false);
      } else {
        setIsUniqueError(null);
        setShowLoading(false);
      }
    } catch (error) {
      console.error('Error checking unique URL:', error);
      setShowLoading(false);
    }
  };

  useEffect(() => {
    if (!uniqueUrl) {
      setIsUniqueError(null);
      setShowLoading(false);
      return;
    }
    checkIsUniqueUrl(uniqueUrl);
  }, [debounceSearch]);

//   const debouncedHandleInputChange = _debounce((value: string) => {
//     onUniqueUrlChange(value);
//   }, 200);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onUniqueUrlChange(value);
    // debouncedHandleInputChange(value);
  };

  return {
    showError,
    isUniqueError,
    showLoading,
    handleInputChange,
  };
};

export default useUniqueUrlValidation;