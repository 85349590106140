import axios from "axios";
import { Promise } from "es6-promise";
import { TokenStorage } from "./token-storage";

const loginRoute = '/login';

export default (axiosInstance: any, request: any) => {
  axiosInstance.interceptors.request.use(
    (config: any) => {
      const authData = request ? request.session.get("auth") : null;
      const authHeader = TokenStorage.getAuthentication(authData);
      config.headers.Authorization = authHeader?.headers?.Authorization;
      return config;
    },
    (error: any) => {
      Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response: any) => {
      // Return a successful response back to the calling service
      return response;
    },
    (error: any) => {
      // Return any error which is not due to authentication back to the calling service
      if (!error.message.includes("401")) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      // Logout user if token refresh didn't work or user is disabled
      if (
        error.config.url == "/api/account/signin" ||
        error.response.message == "Account is disabled."
      ) {
        TokenStorage.clear();
        // router.push({ name: 'root' });

        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
      const authData = request ? request.session.get("auth") : null;

      const refreshToken = TokenStorage.getRefreshToken()

      // if(!refreshToken){
      //   if(!window.location.pathname.toLowerCase().startsWith(loginRoute))
      //     window.location.replace(loginRoute)

      //   return new Promise((resolve, reject) => {
      //     reject(error);
      //   });
      // }
      
      // Try request again with new token
      return TokenStorage.getNewToken(authData)
        .then(async (userAuth) => {
          const token = userAuth.access_token;
          // New request with new token
          const config = error.config;
          if (request) {
            //setting cookie
            request.session.set("auth", userAuth);
            await request.session.save();
          }
          config.headers["Authorization"] = `Bearer ${token}`;

          return new Promise((resolve, reject) => {
            axios
              .request(config)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          });
        })
        .catch((error1) => {
           //Promise.reject({response: {status: 401, data: error}});
          //  if(!window.location.pathname.toLowerCase().startsWith(loginRoute))
          //    window.location.replace(loginRoute)
          //  throw error;
        });
    }
  );
};
