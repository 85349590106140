import { Navigate, Outlet, Route } from 'react-router-dom'
import useCheckIfUserLogin from '../swr/checkIfUserLogin';

// import useCheckIfUserLogin from '../swr/checkIfUserLogin';

const AuthorizeRoute =  ({
    children,
    path
  }) =>{
    const {authenticated , isLoading , isError} = useCheckIfUserLogin()
    return(
        isLoading? 
            null
        :
        authenticated?
            <div>
                {children}
            </div>
        
        : 
            <Navigate to={`/account/login?ReturnUrl=${path}`} />
    )
}

export default AuthorizeRoute