import useSWR from "swr";
import { axiosInstance } from "../services/AxiosInstance";
import {UserPreferencesClient } from "../swagger";

function useUserPhoneNumbers() {
    var client = new UserPreferencesClient(null, axiosInstance);
    const {data, error} = useSWR(`userPreferences.getAllPhoneNumbers`, () => client.getAllPhoneNumbers())
    return {
        phoneNumbers: data,
        isLoading: !error && !data,
        isError: error
      }
}

export default useUserPhoneNumbers;