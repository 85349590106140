import useSWR from "swr";
import { axiosInstance } from "../services/AxiosInstance";
import {UserPreferencesClient } from "../swagger";

function useGetLoginSessions() {
    var client = new UserPreferencesClient(null, axiosInstance);
    const {data, error} = useSWR(`userPreferences.getLoginSessions`, () => client.getLoginSessions())
    return {
        loginSessions: data,
        isLoading: !error && !data,
        isError: error
      }
}

export default useGetLoginSessions;