// Encoding UTF8 ⇢ base64
import { Base64 } from 'js-base64'

export function b64EncodeUnicode(str) {
    return Base64.decode(str)
}

// Decoding base64 ⇢ UTF8

export function b64DecodeUnicode(str) {
    return Base64.encode(str)
}

