
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import _ from 'lodash';
import {Fragment , useState} from 'react'
import toast from "react-hot-toast";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { axiosInstance } from '../../services/AxiosInstance';
import { InviteClient } from '../../swagger';
import getUserInfoFromInviteToken from '../../swr/getUserInfoFromInviteToken'
import getUserInfo from '../../swr/getUserInfo'

const HasAccount = props =>{
    const {t} = useTranslation('identity.')
    const {user , isLoadingUserApi , isErrorUserApi} = getUserInfo()
    const {users , isLoadingUsersApi , isErrorUsersApi} = getUserInfoFromInviteToken(props.token)
    const [loading, setLoading] = useState(false)
    const newUser = !_.isEmpty(users)?users[0]:null
    const inviteUser = {
        name:!_.isEmpty(users)?users[1].userName:"",
        email:!_.isEmpty(users)?users[1].email:""
    }

    const addToAccount = async () =>{
        setLoading(true);
        const client = new InviteClient(undefined, axiosInstance);
        try {
            var res:any = null;
            res = await client.addEmailToAccountFromInviteToken(props.token);
            
            if (res && res.succeeded) {
                window.location.replace("/account/profile");                
                setLoading(false);
                // TokenStorage.storeToken(res.data. as string);
                // TokenStorage.storeRefreshToken(res.refresh_token as string);
            } else {
                setLoading(false);
                toast.error(_.first(res.errors) as any)
                // addToast(res.error, { appearance: "error" });
            }
        } catch (error) {
            setLoading(false);
            toast.error(t("identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"))
            // addToast("Server Error Occured", { appearance: "error" });
        }
        setLoading(false)
      };

    
    return(
        props.token && user &&  !_.isEmpty(users) ?
        <Fragment>
            <div className="mx-auto grid place-items-center min-h-screen">
                <div className=" self-center h-fit organizer clearfix registerFormContainer rounded overflow-visible lg:shadow-lg p-6">
                    <div className="sizeHandler">
                        <div className="signTop organizer clearfix" >
                            <div className="flex  gap-x-6 place-items-center place-content-center organizer loginForm fln m0a grid-6 grid-t-12">
                                <div className="registerImage sm:block hidden border-r-2  border-grey-50 leftFloat centerFlex halfColumn">
                                    <img src="/img/sign.svg" className="h-80"/>
                                </div>
                                <div className=" rightFloat halfColumn leftBorder organizer flex flex-col gap-y-8"> 
                                    <div className="centerKeeper textInCenter flex flex-col gap-y-5 text-center">
                                        <img src='/img/Voxdash.svg' className='h-10' />
                                        <div className='flex p-1 gap-1 border border-1 rounded-md border-gray-200 shadow-sm'>
                                            <img src='img/mr.svg' className='h-5 w-5 rounded-full border-1 border-blue-700'/>
                                            <p className='text-sm text-blue-700'>{user?.userName}</p>
                                            <p className='text-sm'>{t('identity.inviteViaEmail.isAlreadyLogedIn')}</p>
                                        </div>
                                        <p className="text-l font-medium normalDoubleBottomMargin text-gray-600">
                                            {inviteUser?.name}({inviteUser?.email}){t('identity.inviteViaEmail.hasInvited')}{" "+newUser?.email+" "}{t('identity.inviteViaEmail.YouToVoxdash')}
                                        </p> 
                                   
                                        <button onClick={addToAccount} type="submit" className="cursor-pointer text-white bg-[#00917c] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center block  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            {t("identity.inviteViaEmail.AddEmailToMyAccount")}
                                            <svg className="float-right flex w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </button>
                                        <button onClick={() => window.location.replace("/Account/ChangePass?token="+props.token)} type="submit" className="cursor-pointer text-white bg-[#00917c] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center block  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                            {t("identity.inviteViaEmail.CreateNewAccount")}
                                            <svg className="float-right flex w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </button>
                                        <p className="text-xs normalDoubleBottomMargin text-gray-600">
                                            {t("identity.src.components.login.CreatePassword.ByCreatingAnAcount,YouAgreeToPeopleAnalyticsAnd")}
                                            
                                            
                                            <a target="_blank" href={process.env.REACT_APP_PUBLIC_URL+"/policies/Privacy%20Policy"} className="cursor-pointer text-xs text-bold underline inline-block align-baseline text-green-700 hover:text-green-800">
                                                {t("identity.src.components.login.CreatePassword.PrivacyNotice.")}
                                            </a>
                                            
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
        :null
    )
}


export default HasAccount