import classNames from "classnames";
import { useRef, useState } from "react";
import {
  CheckCircleIcon,
  CheckIcon,
  ChevronUpDownIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import { DebounceInput } from "react-debounce-input";
import Select, { components, DropdownIndicatorProps } from "react-select";
import { Listbox } from "@headlessui/react";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import Tippy from "@tippyjs/react";
import { Combobox, Transition } from "@headlessui/react";

export const Option = (props: any) => {
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
  } = props;
  return (
    <div
      ref={innerRef}
      className={classNames(
        { "text-white bg-indigo-600": isSelected },
        { "text-gray-900": !isSelected },
        "relative cursor-default select-none py-2 pl-3 pr-9"
      )}
      {...innerProps}
    >
      <span
        className={classNames(
          isSelected ? "font-semibold" : "font-normal",
          "block truncate sm:text-sm"
        )}
      >
        {children}
      </span>
      {isSelected ? (
        <span
          className={classNames(
            isSelected ? "text-white" : "text-indigo-600",
            "absolute inset-y-0 right-0 flex items-center pr-4"
          )}
        >
          <CheckIcon className="h-5 w-5" aria-hidden="true" />
        </span>
      ) : null}
    </div>
  );
};

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
    </components.DropdownIndicator>
  );
};

export const ClearIndicator = ({ innerProps }: any) => {
  return <span {...innerProps} />;
};

export const IndicatorSeparator = ({ innerProps }: any) => {
  return <span {...innerProps} />;
};

type selectType = {
  input: any;
  value?: any;
  name: string;
  onChange: (e: any) => void;
  label: string;
  isMulti: boolean;
  disabled?: boolean;
  cssClasses?: string;
  tooltip?: string;
  description?: string;
  defaultVal?: any;
  options: any;
  getOptionValue?: (e: any) => any;
  getOptionLabel?: (e: any) => any;
  t: any;
  t1: any;
  meta: any;
};

const CheckHasLabel = (
  value: any,
  options: any,
  getOptionLabel: any,
  getOptionValue: any
) => {
  console.log("value", value);
  if (!value) return value;
  if (getOptionLabel) {
    if (getOptionLabel(value) != null && getOptionLabel(value) != "")
      return value;
  } else if (value.label != null && value.label != "") {
    return value;
  }
  if (getOptionValue) {
    const newValue = options.filter(
      (o: any) => getOptionValue(o) == getOptionValue(value)
    );
    if (newValue.length > 0) {
      return newValue[0];
    }
  } else {
    const newValue = options.filter((o: any) => o.id == value.id);
    console.log("newValue", newValue);
    if (newValue.length > 0) {
      return newValue[0];
    }
  }
  return value;
};

export const SingleValue = ({ children, ...props }: any) => (
  <components.SingleValue {...props}>
    <span className="relative w-full cursor-default py-2 text-left shadow-sm sm:text-sm block truncate">
      {children}
    </span>
    {/* <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"></span> */}
  </components.SingleValue>
);

const customStyles = {
  control: (provided, state) => ({
    //...provided ,
    // height:'inherit',
    overflow: "visible",
    display: "flex",
    height: 44,
    border: "solid #ebedef 1.5px",
    borderRadius: "10px",
    // overflow:'visible',
    fontSize: "0.8rem",
    color: "black",
  }),
  valueContainer: (prev, state) => ({
    ...prev,
    display: "flex",
    padding: "2px 0px 2px 8px",
  }),

  // menu: (provided, state) => ({
  //   ...provided,
  //   zIndex : 2,
  //   overflow:'visible',
  //   fontSize:'14px'
  // }),
  // multiValue: (provided, state) => ({
  //   ...provided,
  //   borderRadius: '10px!important',
  //   fontSize:'14px'
  // })
};

const CustomeSingleValue = (props) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div className="items-center flex" ref={innerRef} {...innerProps}>
      {data.icon ? (
        <>
          <span
            className={`rounded fi fi-${data.icon.toLowerCase()} fis`}
          ></span>
          <span style={{ fontSize: "12px", paddingLeft: "5px" }}>
            {" "}
            {data.icon}
          </span>
          <span style={{ fontSize: "12px" }}> (+{data.code})</span>
        </>
      ) : (
        <>
          <img src={data.img} className="rounded h-5 w-5 " />
          <span style={{ fontSize: "12px", paddingLeft: "5px" }}>
            {data.name}
          </span>
        </>
      )}
    </div>
  );
};

export const RenderSelectInput = (FieldProps) => {
  return (
    <Select
      {...FieldProps}
      classNamePrefix="select"
      value={FieldProps.value}
      onChange={
        FieldProps.onChange
          ? FieldProps.onChange
          : (option) =>
              FieldProps.form.setFieldValue(FieldProps.field.name, option)
      }
      isLoading={false}
      isClearable={true}
      isRtl={false}
      styles={FieldProps.styles ? FieldProps.styles : customStyles}
      getOptionLabel={
        FieldProps.getOptionLabel
          ? FieldProps.getOptionLabel
          : (opt: any) => opt.label
      }
      getOptionValue={
        FieldProps.getOptionValue
          ? FieldProps.getOptionValue
          : (option: any) => option.id
      }
      getLabel
    />
  );
};

export const SelectTelCodeInput = (FieldProps) => {
  return (
    <div className=" border-none flex-shrink-0 z-1 inline-flex items-center text-sm font-medium text-center rounded-l-lg hover:bg-gray-50 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800">
      <Select
        styles={{
          control: (provided, state) => ({
            //...provided ,
            borderRight: "solid #ebedef 1.5px",
            height: "inherit",
            overflow: "visible",
            display: "flex",
            fontSize: "0.8rem",
            color: "black",
            cursor: "pointer",
            paddingRight: "10px",
            // height: 44,
            // overflow:'visible',
            // fontSize:'14px'
          }),
          valueContainer: (prev, state) => ({
            ...prev,
            display: "flex",
            padding: "2px 0px 2px 8px",
            flexWrap: "nowrap",
          }),
          indicatorsContainer: (prevStyle, state) => ({
            //...prevStyle,
            width: "30px",
            height: "30px",
          }),
          menu: (provided, state) => ({
            ...provided,
            zIndex: 10,
            overflow: "visible",
            cursor: "pointer",
            //fontSize:'14px'
          }),
          // multiValue: (provided, state) => ({
          //   ...provided,
          //   borderRadius: '10px!important',
          //   fontSize:'14px'
          // })

          //  menuList: (provided, state) => ({
          //   ...provided ,
          //   width: 250
          // }),
          // menu: (provided, state) => ({
          //   ...provided ,
          //   width: 250,
          //   textAlign: 'left',
          //   display: 'flex',
          //   gap: 10,
          //   cursor: 'pointer'
        }}
        className={FieldProps.cssClasses}
        value={FieldProps.value}
        onChange={
          FieldProps.onChange
            ? FieldProps.onChange
            : (option) =>
                FieldProps.form.setFieldValue(FieldProps.field.name, option)
        }
        getOptionLabel={(opt) => opt.code}
        getOptionValue={(option) => option.code}
        components={{
          IndicatorSeparator: () => null,
          Option: CustomOption,
          SingleValue: CustomeSingleValue,
        }}
        isMulti={false}
        isDisabled={FieldProps.disabled}
        isSearchable={true}
        options={FieldProps.options}
        placeholder={FieldProps.placeholder}
        // onInputChange={(e) => console.log("---", e)}
        // filterOption = { e => console.log("---",e)}
      />
    </div>
  );
};

const CustomOption = (props) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div
      className=" items-center flex h-7"
      ref={innerRef}
      {...innerProps}
      style={{ padding: "0 0.5rem" }}
    >
      {/* <img
          src={data.Icon}
          style={{ paddingRight: "5px", paddingLeft: "10px" }}
        /> */}
      {data.icon ? (
        <>
          <span className={`fi fi-${data.icon.toLowerCase()} fis`}></span>
          {/* <span style={{ fontSize: "12px"}}> {(data.label)}</span> */}
          <span style={{ fontSize: "14px" }}> +{data.code}</span>
        </>
      ) : (
        <>
          <img className="h-5 pr-1" src={data.img} />
          <span style={{ fontSize: "14px" }}>{data.name}</span>
        </>
      )}
    </div>
  );
};

export const telephoneInput = (FieldProps) => {
  return (
    <div className="relative w-full">
      <input
        className="form-control border-none block p-2.5 w-full z-20 text-sm rounded-r-lg border-l-gray-100 border-l-2 border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:border-blue-500"
        type={FieldProps.type}
        disabled={FieldProps.disabled}
        //style={{height:'inherit' , fontSize:'14px' , marginLeft:'2px' , borderRadius:'5px'}}
        //className="form-control"
        aria-label="Text input with dropdown button"
        placeholder={FieldProps.placeholder}
        value={FieldProps.field.value}
        onChange={(option) =>
          FieldProps.form.setFieldValue(
            FieldProps.field.name,
            option.target.value
          )
        }
      />
      {FieldProps.validationError ? (
        <span className={classNames("inputGuide input-group")}>
          {FieldProps.validationError}
        </span>
      ) : null}
    </div>
  );
};

export const DebounceSimpleInput = (FieldProps) => {
  return (
    <div>
      <label
        htmlFor="company-website"
        className="flex items-start text-sm font-medium text-gray-700"
      >
        {FieldProps.label}
        <span className="text-red-600">*</span>
      </label>
      <div className="flex items-center mt-1">
        <div
          className={classNames(
            {
              "border border-red-300 placeholder-red-300 focus:ring-red-500  text-red-900  focus:border-red-500":
                FieldProps.hasError,
            },
            "flex px-2 shadow-sm w-full bg-white border rounded-md border-gray-300 focus:outline-none"
          )}
        >
          <span className=" flex-none self-center pointer-events-none text-gray-500 sm:text-sm">
            voxdash.com/
          </span>
          <DebounceInput
            name={FieldProps.field.name}
            debounceTimeout={300}
            type={FieldProps.type}
            value={
              FieldProps.initialValues
                ? FieldProps.initialValues
                : FieldProps.field.value
            }
            onChange={(option) =>
              FieldProps.onChange
                ? FieldProps.onChange(option)
                : FieldProps.form.setFieldValue(
                    FieldProps.field.name,
                    option.target.value
                  )
            }
            disabled={FieldProps.disabled}
            className="w-full sm:text-sm focus:outline-none border-none pl-0"
          />
          {FieldProps.loading ? (
            <div className="pointer-events-none flex items-center">
              <svg
                role="status"
                className="inline h-4 w-4 text-blue-500 animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            </div>
          ) : FieldProps.isValid ? (
            <div className="pointer-events-none flex items-center">
              <CheckCircleIcon className="h-5 w-5 text-green-500" />
            </div>
          ) : FieldProps.isValid == false || FieldProps.hasError ? (
            <div className="pointer-events-none flex items-center">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export const SelectOptionInput = (FieldProps) => {
  return (
    <Select
      styles={{
        placeholder: (base) => ({
          ...base,
          display: "flex",
          fontSize: "0.875rem",
          color: "black",
        }),
      }}
      closeMenuOnSelect={true}
      placeholder={"Select"}
      components={{
        Option: Option,
        DropdownIndicator: DropdownIndicator,
        IndicatorSeparator: IndicatorSeparator,
        SingleValue: SingleValue,
      }}
      name="salutation"
      // defaultValue={salutationOptions? salutationOptions[0]:null}
      value={FieldProps.form.salutation}
      className="grow"
      // CheckHasLabel(input ? input.value : value || (defaultVal), options, getOptionLabel, getOptionValue)}
      isMulti={false}
      isDisabled={false}
      isSearchable={true}
      isClearable={true}
      options={FieldProps.options}
      onChange={(option) =>
        FieldProps.onChange
          ? FieldProps.onChange(option)
          : FieldProps.form.setFieldValue(FieldProps.field.name, option)
      }
      // getOptionLabel={(opt: any)=>opt.label}
      // getOptionValue={
      //     (option: any) => option.id
      // }
    />
  );
};

export const RadioInput = (props: any) => {
  return (
    <>
      <div>
        <fieldset className="mt-4">
          <div className="space-y-4">
            {props.notificationMethods.map((notificationMethod) => (
              <div key={notificationMethod.id} className="flex items-center">
                <input
                  id={notificationMethod.id}
                  name="notification-method"
                  type="radio"
                  defaultChecked={notificationMethod.id === "email"}
                  className="h-4 w-4 border-gray-300 text-[#0052CC] focus:ring-[#0052cc88]"
                />
                <label
                  htmlFor={notificationMethod.id}
                  className="ml-3 block text-[14px] font-[600] text-[#091E42]"
                >
                  {notificationMethod.title}
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
    </>
  );
};

export const TextInput = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: any) => (
  <div className={` ${props.className}`}>
    <label htmlFor="email" className="block text-[16px] font-[600] leading-6 ">
      {props.title}
    </label>
    <div className="relative mt-2 rounded-md">
      {props.icon && (
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <span
            className={`icon-${props.icon} text-DIMO_Colors/Tertiary/Grey/Grey400`}
            aria-hidden="true"
          ></span>
        </div>
      )}

      {props.isPassword ? (
        <>
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 cursor-pointer">
            <svg
              fill="none"
              onClick={() => props.setShowPassword(!props.showPassword)}
              className={classNames("h-3 text-gray-700", {
                hidden: !props.showPassword,
                block: props.showPassword,
              })}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
            >
              <path
                fill="currentColor"
                d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
              ></path>
            </svg>

            <svg
              fill="none"
              onClick={() => props.setShowPassword(!props.showPassword)}
              className={`h-3 text-gray-700 ${props.showPassword ? "hidden" : "block"}`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
            >
              <path
                fill="currentColor"
                d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
              ></path>
            </svg>
          </div>
        </>
      ) : (
        <></>
      )}

      <input
        type={
          props.isNumber ? "number" : props.isPassword && !props.showPassword ? "password" : props.showPassword ? "text" : "text"
        }
        {...field}
        {...props}
        className={classNames(
          "block font-light w-full rounded-md border-0 py-2 text-gray-900 ring-1 ring-inset ring-gray-200 focus-visible:outline-none placeholder:text-gray-400 focus:ring-primary_blue text-base sm:text-sm sm:leading-6 disabled:opacity-50",
          props.icon ? "pl-10" : "pl-4"
        )}
        placeholder={`${props.placeholder || props.title}`}
        disabled={props.disabled}
      />
    </div>
    <div className="mt-1 text-xs font-medium text-red-500">
      {touched[field.name] && errors[field.name] ? errors[field.name] : " "}
    </div>
  </div>
);

export const SelectInputCombobox = ({
  field,
  form: { touched, errors },
  ...props
}: any) => {
  const onChange = (value: any) => {
    props.setQuery(value);
  };
  const comboBtn = useRef(null);
  const onSearchInputFocus = () => comboBtn.current?.click();

  const onInputFocus = (event) => {
    if (props.disabled) {
      event.target.value = "";
      event.target.disabled = true;
    }
  };
  return (
    <>
      <div>
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {props.title}
        </label>
        <div className="relative mt-2 rounded-md">
          {props.icon && (
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <span
                className={`icon-${props.icon} text-DIMO_Colors/Tertiary/Grey/Grey400`}
                aria-hidden="true"
              ></span>
            </div>
          )}
          <Combobox value={props.value} onChange={props.onChange}>
            <div className="relative mt-1">
              <div className="h-12 cursor-pointer block font-light w-full rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-200 focus-visible:outline-none placeholder:text-gray-400 focus:ring-primary_blue text-base sm:text-sm sm:leading-6 disabled:opacity-50">
                <Combobox.Button
                  className="absolute inset-y-0 right-0 flex items-center w-full"
                  ref={comboBtn}
                >
                  <Combobox.Input
                    className="block  cursor-pointer font-light w-full rounded-md border-0 py-3 px-3 text-gray-900 ring-1 ring-inset ring-gray-200 focus-visible:outline-none placeholder:text-gray-400 focus:ring-primary_blue text-base sm:text-sm sm:leading-6 disabled:opacity-50"
                    displayValue={(person: any) => person?.name}
                    onChange={(value) => onChange(value.target.value)}
                    onFocus={onInputFocus}
                  />

                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400 absolute inset-y-0 right-2 top-1/2 transform -translate-y-1/2"
                    aria-hidden="true"
                  />
                </Combobox.Button>
              </div>
              <Combobox.Options className="absolute z-[100000] my-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-200 ring-opacity-5 focus:ring-primary_blue sm:text-sm">
                {props.options?.length === 0 && props.query !== "" ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  props.options.map((person: any) => (
                    <Combobox.Option
                      key={person.id}
                      className={({ active }) =>
                        `relative select-none py-2 pl-10 pr-4 cursor-pointer ${
                          active
                            ? "bg-primary_blue bg-opacity-80 text-blue-800"
                            : "text-gray-900"
                        }`
                      }
                      value={person}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={`block truncate ${
                              selected ? "font-medium" : "font-normal"
                            }`}
                          >
                            {person.name}
                          </span>
                          {selected ? (
                            <span
                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                active ? "text-white" : "text-primary_blue"
                              }`}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </div>
          </Combobox>
        </div>
        <div className="mt-1 text-xs font-medium text-red-500">
          {touched[field.name] && errors[field.name] ? errors[field.name] : " "}
        </div>
      </div>
    </>
  );
};

export const formatDate = (date: Date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = "" + d.getHours(),
    min = "" + d.getMinutes();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  if (min.length < 2) {
    min = "0" + min;
  }
  if (hour.length < 2) {
    hour = "0" + hour;
  }

  return [year, month, day].join("-") + ", " + [hour, min].join(":");
};
