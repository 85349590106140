

const NUMBER_PATTERN = /[0-9]+/g;
const LOWER_CASE_PATTERN = /[a-z]+/g;
const UPPER_CASE_PATTERN = /[A-Z]+/g;


export const CalculateAlphanumericPasswordStrengthLevel = (password) => {
    let passScore = 0;
    let requirements = 0;

    passScore += lengthWeight(password);

    if (lengthGreaterThanEight(password)) {
        requirements += 1;
    }

    if (checkDigitCharacter(password)) {
        requirements += 1;
        let countOfDigitChar = countSpecificCharacter(password, NUMBER_PATTERN);
        if (countOfDigitChar !== password.length) {
            passScore += countOfDigitChar * 4;
        }
    }

    if (checkNonAlphaNumericCharacter(password)) {
        requirements += 1;
        passScore += countNonAlphaNumericCharacter(password) * 6;
    }

    if (checkLowerCase(password)) {
        requirements += 1;
        passScore += (password.length - countSpecificCharacter(password, LOWER_CASE_PATTERN)) * 2;
    }

    if (checkUpperCase(password)) {
        requirements += 1;
        passScore += (password.length - countSpecificCharacter(password, UPPER_CASE_PATTERN)) * 2;
    }

    passScore += requirements * 2;
    passScore += (countMiddleNumericAndNonAlphaNumericCharacter(password) * 2);
    passScore -= countAlphaSequence(password, 3) * 3;
    passScore -= countNumSequence(password, 3) * 3;
    passScore -= countSymbolSequence(password, 3) * 3;
    passScore -= passwordHaveOnlyDigitsLength(password);
    passScore -= passwordHaveOnlyLettersLength(password);
    passScore -= countConsecutiveCharacter(password, NUMBER_PATTERN) * 2;
    passScore -= countConsecutiveCharacter(password, LOWER_CASE_PATTERN) * 2;
    passScore -= countConsecutiveCharacter(password, UPPER_CASE_PATTERN) * 2;
    passScore -= calculateRepeatedCharScore(password.replaceAll(" ", ""));

    return Math.round(calculateStrengthLevel(passScore));
}

// var calculateNumericPasswordStrengthLevel = (password) =>  {
//     let passScore = lengthWeight(password);
//     let requirements = 0;

//     if (lengthGreaterThanEight(password)) {
//         requirements += 1;
//     }

//     passScore += requirements * 2;
//     passScore -= countNumSequence(password, 3) * 2;
//     passScore -= countRepeatCharacter(password) * 2;

//     return Math.round(calculateStrengthLevel(passScore));
// }

var countConsecutiveCharacter= (password, pattern)=> {
    let count = 0;
    let matches = password.replaceAll(" ", "").match(pattern);
    if (matches) {
        matches.forEach((seq) => {
            if (seq.length > 1) {
                count += seq.length - 1;
            }
        });
    }
    return count;
}

var passwordHaveOnlyLettersLength = (password) =>  {
    return /^[A-Za-z]+$/.test(password.replaceAll(" ", "")) ? password.length : 0;
}

var passwordHaveOnlyDigitsLength = (password) =>  {
    return /^[0-9]+$/.test(password.replaceAll(" ", "")) ? password.length : 0;
}

var countMiddleNumericAndNonAlphaNumericCharacter = (password) =>  {
    if (password.length > 1) {
        return countNonAlphaNumericCharacter(password.slice(1, -1)) +
                countSpecificCharacter(password.slice(1, -1), NUMBER_PATTERN);
    }
    return 0;
}

var countRepeatCharacter = (password) => {
    let counts = {};
    let repeatCount = 0;
    for (let char of password.toLowerCase()) {
        counts[char] = (counts[char] || 0) + 1;
    }
    Object.values(counts).forEach((count) => {
        if (count > 1) {
            repeatCount += count;
        }
    });
    return repeatCount;
}

var lengthGreaterThanEight = (password) => {
    return password.length > 8;
}

var calculateStrengthLevel = (passScore) => {
    if (passScore < 0) passScore = 0;
    if (passScore > 100) passScore = 100;
    return passScore / 10;
}

var lengthWeight = (password) => {
    return 4 * password.length;
}

var countSymbolSequence = (password, sequenceLength) => {
    var count = 0
    let qwertySeqPattern = /qwerty|asdf|zxcv|tyuio/g; // simplified
    // Use regex to count symbol sequences like qwerty etc.
    for (let i = 0; i < password.length - sequenceLength + 1; i++) {
        let part = password.substring(i, i + sequenceLength);
        let match = part.match(qwertySeqPattern);
        if(match){
            if (!part.match(NUMBER_PATTERN) && !/^[a-zA-Z]+$/.test(part)) {
                count++;
            }
        }
    }

    return count
}

var countNumSequence = (password, sequenceLength) => {
    // Check for numeric sequences
    let count = 0;
    for (let i = 0; i < password.length - sequenceLength + 1; i++) {
        let part = password.substring(i, i + sequenceLength);
        if (/^\d+$/.test(part) && isAlphabetical(part)) {
            count++;
        }
    }
    return count;
}

var isAlphabetical = (str) => {
    const original = str.toLowerCase(); // Convert to lowercase to ignore case
    const sorted = original.split('').sort().join(''); // Sort characters in alphabetical order
    return original === sorted; // Check if original matches sorted version
}

var countAlphaSequence = (password, sequenceLength) => {
    // Check for alphabetical sequences
    let count = 0;
    for (let i = 0; i < password.length - sequenceLength + 1; i++) {
        let part = password.substring(i, i + sequenceLength);
        if (isAlphabetical(part)) {
            count++;
        }
    }
    return count;
}

var countSpecificCharacter = (password, pattern) => {
    let match = password.match(pattern);
    return match ? match.join('').length : 0;
}

var countNonAlphaNumericCharacter = (password)  => {
    return password.replace(/[a-zA-Z0-9\s]/g, '').length;
}

var checkUpperCase = (password) => {
    return /[A-Z]/.test(password);
}

var checkLowerCase = (password) => {
    return /[a-z]/.test(password);
}

var checkDigitCharacter = (password) => {
    return /\d/.test(password);
}

var checkNonAlphaNumericCharacter = (password) => {
    return /[^a-zA-Z0-9\s]/.test(password);
}

var calculateRepeatedCharScore = (password) => {
    let countRepeatedChar = 0;
    let repeatedCharScore = 0;

    for (let i = 0; i < password.length; i++) {
        var repeatedCharExist = false;
        for (let j = 0; j < password.length; j++) {
            if (password[i] === password[j] && i !== j) {
                repeatedCharExist = true;
                repeatedCharScore += Math.abs(password.length / (j - i));
            }
        }
        if (repeatedCharExist) {
            countRepeatedChar++;
            var countUniqueChar = password.length - countRepeatedChar;
            repeatedCharScore = countUniqueChar != 0 ? Math.round(repeatedCharScore / countUniqueChar) : Math.round(repeatedCharScore);
        }
    }

    return repeatedCharScore;
}
