import useSWR from "swr";
import { axiosInstance } from "../services/AxiosInstance";
import {AccountClient, UserinfoClient } from "../swagger";

function useCheckIfUserLogin() {
    var client = new AccountClient(null, axiosInstance);
    const {data, error} = useSWR(`account.checkLogin`, () => client.checkLogin())
    return {
        authenticated : data,
        isLoading: !error && data == undefined,
        isError: error
      }
}

export default useCheckIfUserLogin;