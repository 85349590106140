import React, { Suspense } from 'react';
import './App.css';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "flag-icons/css/flag-icons.min.css";
import Login from './pages/User/Login/index';
import ForgetPassword from './pages/User/ForgetPassword';
import Profile from './pages/profile/index'
import ResetPassword from './pages/User/ForgetPassword/ResetPassword';
import CompletePersonalInfo from './components/login/CompletePersonalInfo';
import { I18nextProvider } from 'react-i18next';
import InviteViaEmail from './components/InviteUser/mainPage'
import ChangeInvitedPass from './components/InviteUser/changePass';
import config from './i18n.js';
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css';
import 'react-circular-progressbar/dist/styles.css';
import Loading from './pages/Loading';
import AuthorizeRoute from './components/AuthorizeRoute';
import Index from './pages/Index';
function App() {
  return (  
    <I18nextProvider i18n={config}>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <Routes >
              <Route path="/Index" element={<Index/>} /> 
              <Route path="/" element={<Index/>} /> 
              <Route path="Account/Login" element={<Login redirectUrl={window.location.search}/>} /> 
              {/* <Route path="Account/Register" element={<Register/>} /> */}
              <Route path="Account/ForgetPassword" element={<ForgetPassword/>} />
              {/* <Route path="Account/SetPassword" element={<ResetPassword/>}/> */}
              <Route path="Account/Profile" element={<AuthorizeRoute path='/Account/profile'><Profile/></AuthorizeRoute>}/>
              <Route path="Account/InviteViaEmail" element={<InviteViaEmail/>}/>
              <Route path ="Account/ChangePass" element={<ChangeInvitedPass/>}/>
              <Route path="Account/CompletePersonalInfo" element={<CompletePersonalInfo redirectUrl={null} form={{}} setStep = {(step)=>window.location.replace('Account/Login')}/>}/>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </I18nextProvider>
  );
}

export default App;
