import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";
import {
  ResendEmailActivationForNewEmailCommand,
  UserEmailIdentity,
  UserPreferencesClient,
} from "../../swagger";
import { useState } from "react";
import { axiosInstance } from "../../services/AxiosInstance";
import toast, { Toaster } from "react-hot-toast";

const EmailComponent = (props) => {
  const email: UserEmailIdentity = props.email;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    console.log("props.email", props.email);
    setLoading(true);
    const client = new UserPreferencesClient(undefined, axiosInstance);
    try {
      const command = {
        user: undefined,
        email: props.email.email,
      } as ResendEmailActivationForNewEmailCommand;

      var res = await client.resendEmailActivation(command);

      if (res?.succeeded) {
        toast.success(t("identity.profile.CodeSentAgain"));
        setLoading(false);
        props.cancelAction();
      } else {
        setLoading(false);
        toast.error("error");
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        t(
          "identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"
        )
      );
    }
    setLoading(false);
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={true}></Toaster>
      <div className="flex items-center">
        <p className="text-sm text-gray-400">{email.email}</p>
        {email.emailConfirmed ? (
          <div className="rounded-2xl ml-2 px-2 py-1 w-fit bg-green-100 p-1">
            <div className="flex items-center">
              <p className="mr-1 text-xs font-medium text-green-500">
                {t("identity.emailAddresses.verified")}
              </p>
              <CheckCircleIcon className="h-5 w-5 text-xs  text-green-500" />
            </div>
          </div>
        ) : null}
      </div>
      {!email.emailConfirmed ? (
        <div className="border-amber-100 bg-amber-100 rounded-md p-2 w-fit mb-2">
          <div className="flex flex-row space-x-1 items-center  ">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" />
            </div>
            <div className="ml-1 ">
              <div className="text-sm font-medium  text-yellow-600">
                {t("identity.emailAddresses.thisEmailHasNotBeenVerified")}{" "}
                <a
                  onClick={handleClick}
                  href="#"
                  className="font-medium text-yellow-600 underline hover:text-yellow-600 cursor-pointer"
                >
                  {t("identity.emailAddresses.ResendEmail")}
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default EmailComponent;
