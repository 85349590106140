import { useTranslation } from "react-i18next";
import { useEffect, useState, useMemo } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { PencilSquareIcon } from "@heroicons/react/20/solid";
import {
  AccountClient,
  CheckOtpCodeCommand,
  TwoFactorProvider,
  UserPhoneNumber,
  UserPhoneNumberDto,
  UserPreferencesClient,
  VerifyTwoFactorAuthenticationCodeCommand,
} from "../../swagger";
import { axiosInstance } from "../../services/AxiosInstance";
import { mutate } from "swr";
import toast, { Toaster } from "react-hot-toast";


type propType = {
  setStep:(step:number) => void,
  twoStep:boolean,
  phoneNumber:UserPhoneNumberDto,
  step:number,
  setTwoFactorEnabled:()=> void,
  twoFactorProvider:TwoFactorProvider
};

const TwoStepVerificationPhoneNumVerify = (props: propType) => {
  const { t } = useTranslation();
  const [code, setCode] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const onSubmit = async () => {
    setLoading(true);
    const client = new UserPreferencesClient(undefined, axiosInstance);
    const accountClient = new AccountClient(undefined, axiosInstance);

    try {
      if (props.twoStep) {
        const accountCommand = {
          user: undefined,
          provider: props.twoFactorProvider,
          code: code,
        } as VerifyTwoFactorAuthenticationCodeCommand;

        var accountRes = await accountClient.verifyTwoFactorAuthenticationCode(
          accountCommand
        );
        if (accountRes && accountRes.succeeded) {
          toast.success("Updated!");
          setLoading(false);
          // window.location.reload();
          mutate("userPreferences.getAllPhoneNumbers");
          // const queryString = new URLSearchParams({ item: "4" }).toString();
          navigate(`/account/profile?activeTab=AccountPreferences`);
          
          
          props.setStep(1);
        } else {
          setLoading(false);
          toast.error(_.first(accountRes.errors) as any);
        }
      } else {
        const command = {
          userPhoneNumber:props.phoneNumber,
          token: code,
          returnUrl: "",
          user: undefined,
        } as CheckOtpCodeCommand;

        var res = await client.checkOtpCode(command);
        if (res && res.succeeded) {
          // window.location.reload();
          navigate(`/account/profile?activeTab=AccountPreferences`);
          toast.success(t("identity.profile.success"));
          setLoading(false);
          mutate("userPreferences.getAllPhoneNumbers");
          props.setTwoFactorEnabled()
          props.setStep(1);
        } else {
          setLoading(false);
          toast.error(_.first(res.errors) as any);
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(t("identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"));
      console.log("error of TwoStepVerificationPhoneNumVerify", error);
    }
    setLoading(false);
  };
  return (
    <>
    <Toaster position="top-center" reverseOrder={true}></Toaster>
      <div className="space-y-6 sm:px-6 md:col-span-9 md:px-0">
        <div className="bg-white pt-6 shadow-[0_3px_5px_0_rgba(9,30,66,0.10)] rounded-[15px] overflow-hidden ">
          <div className="px-4 sm:px-6">
            <h2 className="text-lg font-[600] leading-6 text-gray-900">
              {props.twoFactorProvider == TwoFactorProvider.PhoneNumber?
                t("identity.profile.PhoneNumbers"):
                t("identity.profile.AppAuthenticator")
              }
            </h2>
          </div>

          <div className="mt-6 flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden border-t border-gray-200">
                  <div className="p-6 !pb-11 grid grid-cols-1 gap-y-6">
                    <div className="flex flex-col space-y-3">
                      <p className="text-base font-[600] leading-6 text-gray-900">
                        {t("identity.profile.EnterYourVerificationCode")}
                      </p>
                      {props.twoFactorProvider == TwoFactorProvider.PhoneNumber?
                      <>
                        <p className="text-sm font-[400] w-[280px] leading-8 text-[#828A96]">
                          {t("identity.profile.WellSendAVerificationCode")}
                        </p>
                        <div
                          onClick={() => props.twoFactorProvider == TwoFactorProvider.PhoneNumber?
                            props.setStep(props.step - 1):null}
                          className="flex gap-x-2 items-center cursor-pointer"
                        >
                          <p className="text-[16px] font-[600]">
                            {props.twoStep ? (
                              <>{props.phoneNumber.phoneNumber}</>
                            ) : (
                              <>{`${props.phoneNumber.phoneCountry?.code||""} ${props.phoneNumber.phoneNumber} `}</>
                            )}
                          </p>
                          <div className="flex flex-row justify-center items-center cursor-pointer">
                            <PencilSquareIcon className="h-5 w-5 text-blue-600" />
                            <p className="text-sm text-blue-600">
                              {t("identity.phoneNumbers.Edit")}
                            </p>
                          </div>
                        </div>
                      </>
                      :null}
                    </div>

                    <div className="flex flex-col space-y-2 mt-4">
                      <p className="text-[#000] text-[16px] font-[600] ">
                        {t("identity.phoneNumbers.EnterCode")}
                      </p>
                      <div className="w-[60%] h-[40px] flex flex-row items-center space-x-3">
                        <input
                          onChange={(e) => setCode(e.target.value)}
                          className="rounded-[8px] border-[#C2C9D1] border-[1px] w-[100%] h-full"
                          type="text"
                          placeholder="Enter 6-digit pin"
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          viewBox="0 0 17 17"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M17 8.5C17 13.1944 13.1944 17 8.5 17C3.80558 17 0 13.1944 0 8.5C0 3.80558 3.80558 0 8.5 0C13.1944 0 17 3.80558 17 8.5ZM6.25 7.75C6.25 7.33579 6.58579 7 7 7H7.25338C8.37294 7 9.20458 8.03673 8.96171 9.12963L8.50257 11.1958C8.46787 11.3519 8.58668 11.5 8.74662 11.5H9C9.41421 11.5 9.75 11.8358 9.75 12.25C9.75 12.6642 9.41421 13 9 13H8.74662C7.62706 13 6.79542 11.9633 7.03829 10.8704L7.49743 8.80423C7.53213 8.6481 7.41332 8.5 7.25338 8.5H7C6.58579 8.5 6.25 8.16421 6.25 7.75ZM8 5C8.55228 5 9 4.55228 9 4C9 3.44772 8.55228 3 8 3C7.44771 3 7 3.44772 7 4C7 4.55228 7.44771 5 8 5Z"
                            fill="#9AA9BF"
                          />
                        </svg>
                      </div>
                      <p className="text-[#1A5DBC] text-[16px] font-[400] underline mt-3 cursor-pointer">
                        {t("identity.basicProfileInfo.TroubleWithCode")}
                      </p>
                    </div>

                    <p className="text-[#828A96] text-[15px] font-[400] w-[95%] leading-5">
                      {t(
                        "identity.basicProfileInfo.YourPhoneNumberHelpsUsKeepYourAccount"
                      )}
                    </p>

                    <div className="flex gap-1 flex-row space-x-3">
                      {props.twoFactorProvider == TwoFactorProvider.GoogleAuthenticator?
                        <button
                          onClick={()=> props.setStep(5)}
                          className="w-fit h-fit py-2 px-10 inline-flex justify-center rounded-md text-[#1A5DBC] border-[1px] border-[#1A5DBC]  text-sm font-semibold bg-white shadow-smfocus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                        >
                          {t("identity.basicProfileInfo.Back")}
                        </button>
                      :null}
                      <button
                        onClick={onSubmit}
                        className="w-fit h-fit py-2 px-10 inline-flex justify-center rounded-md bg-[#1A5DBC] text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        {loading ? (
                          <>
                            <svg
                              aria-hidden="true"
                              className="w-4 h-4 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                          </>
                        ) : (
                          <>{t("identity.basicProfileInfo.Verify")}</>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TwoStepVerificationPhoneNumVerify;
