import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
//const HttpBackend = require('i18next-http-backend');
import HttpBackend from 'i18next-http-backend';

const readCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split('; ');

    for (const cookie of cookies) {
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }

    return null; // Cookie not found
  };

  const myCookieValue = readCookie('USER_LOCALE');

i18n.use(HttpBackend)
    .use(LanguageDetector)
    .init({
        lng: myCookieValue || 'en-US',
        fallbackLng: 'en-US',
        preload: ['en-US'],
        ns: ['identity.' ,'country.', 'components.'],
        defaultNS: 'identity.',
        keySeparator: ':',
        backend : {
            loadPath : process.env.REACT_APP_ADMIN_API_URL + "/api/resources/GetResource?lang={{lng}}&ns={{ns}}"
        },
        react: {
            useSuspense: true
        },
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    });
export default i18n;