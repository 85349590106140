import { ExclamationCircleIcon } from "@heroicons/react/20/solid";
import classNames from "classnames";
import { Form, Formik } from "formik";
import _ from "lodash";
import { Fragment, useState } from "react";
import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { axiosInstance } from "../../services/AxiosInstance";
import { InviteClient } from "../../swagger";
import getUserInfoFromInviteToken from "../../swr/getUserInfoFromInviteToken";
import { CalculateAlphanumericPasswordStrengthLevel } from "../PasswordStrengthUtility";
import PasswordComponent from "../PasswordComponent";

const ChangePassword = (props) => {
  const { t } = useTranslation("identity.");
  const [showPassword, setShowPassword] = useState(false);
  const authResult = new URLSearchParams(window.location.search);
  const token = authResult.get("token");
  const [loading, setLoading] = useState(false);
  var [score , setScore] = useState(0)
  var [redirecting , setRedircting] = useState(false)

  const { users, isLoadingUsersApi, isErrorUsersApi } =
    getUserInfoFromInviteToken(token);

  console.log('users', users);

  const newUser = !_.isEmpty(users) ? users[0] : null;
  const inviteUser = {
    name: !_.isEmpty(users) ? users[1].firstName : "",
    email: !_.isEmpty(users) ? users[1].email : "",
  };

  const changePass = async (values) => {
    console.log("pass", values);
    setLoading(true);
    const client = new InviteClient(undefined, axiosInstance);
    try {
      var res: any = null;
      res = await client.changePasswordFromInviteToken(token, values.password);

      if (res && res.succeeded) {
        setRedircting(true)
        window.location.replace("/account/login");

        setLoading(false);
        // TokenStorage.storeToken(res.data. as string);
        // TokenStorage.storeRefreshToken(res.refresh_token as string);
        // window.location.replace("/");
      } else {
        setLoading(false);
        toast.error(_.first(res.errors) as any);
        // addToast(res.error, { appearance: "error" });
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        t(
          "identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"
        )
      );
      // addToast("Server Error Occured", { appearance: "error" });
    }
    setLoading(false);
  };

  const checkValidate = (values) => {
    let error = {} as any;
    if (!values.password || values.password == "") {
      error.password = t("identity.inviteViaEmail.enterAPassword");
      setScore(0)
    } else{
      var newScore = CalculateAlphanumericPasswordStrengthLevel(values.password)
      setScore(newScore)
    }
    return error;
  };

  return token && newUser && inviteUser ? (
    <Fragment>
      <div className="mx-auto grid place-items-center min-h-screen">
        <div className=" self-center h-fit organizer clearfix registerFormContainer rounded overflow-visible lg:shadow-lg p-6">
          <div className="sizeHandler">
            <div className="signTop organizer clearfix">
              <div className="flex  gap-x-6 place-items-center place-content-center organizer loginForm fln m0a grid-6 grid-t-12">
                <div className="registerImage sm:block hidden border-r-2  border-grey-50 leftFloat centerFlex halfColumn">
                  <img src="/img/sign.svg" className="h-80" />
                </div>
                <div className=" rightFloat halfColumn leftBorder organizer flex flex-col gap-y-8">
                  <div className="centerKeeper textInCenter flex flex-col gap-y-5 text-center">
                    <img src="/img/Voxdash.svg" className="h-9" />
                    <p className="text-l font-medium normalDoubleBottomMargin text-gray-600">
                      {inviteUser?.name}({inviteUser?.email})
                      {t("identity.inviteViaEmail.hasInvited")}
                      {" " + newUser.email + " "}
                      {t("identity.inviteViaEmail.ToVoxdash")}
                    </p>
                    <p className="text-l font-medium normalDoubleBottomMargin text-gray-600">
                      {t(
                        "identity.inviteViaEmail.pleaseSetYourPasswordToCreateYourAccount"
                      )}
                    </p>

                    <PasswordComponent
                      getCurrentPassword={false}
                      handleSubmit={changePass}
                      loading={loading}
                      waitForRedirect={redirecting}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  ) : null;
};

export default ChangePassword;
