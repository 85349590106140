import axios from "axios";
import useSWR from "swr";
import { UserPreferencesClient } from "../swagger";
import { axiosInstance } from "../services/AxiosInstance";

function useUserPercentageComplete() {
  var client = new UserPreferencesClient(null, axiosInstance);
  const {data, error} = useSWR(`userPreferences.getPercentageComplete`, () => client.getPercentageComplete())

    return {
        percentage: data,
        isLoading: !error && data==null,
        isError: error
      }
}

export default useUserPercentageComplete;