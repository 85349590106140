import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import _ from "lodash";
import classNames from "classnames";

interface propsType {
  show: boolean;
  closeModal: () => void;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  title?: string;
  className?: string;
}

const defaultProps = {};

const Modal = (props: propsType) => {
  const { show, closeModal, children, footer, title,className } = props;
  return (
    <>
      <Transition.Root show={show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={classNames(
                    "relative transform rounded-sm bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl",
                    className
                  )}
                >
                  <div className="px-6 py-4 sm:px-4 border-b border-greyBorder">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-xl font-medium text-gray-900">
                        {title}
                      </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                          onClick={closeModal}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="block sm:items-start px-4 py-4">
                    {children}
                  </div>
                  {!_.isEmpty(footer) && (
                    <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse border-t border-greyBorder px-4 py-4">
                      {footer}
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

Modal.defaultProps = defaultProps;

export default Modal;
