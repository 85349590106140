import { PlusIcon } from "@heroicons/react/20/solid";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useUserPhoneNumbers from "../../swr/getUserPhoneNumbers";
import NewPhoneForm from "./NewPhoneNumberForm";
import PhoneComponent from "./PhoneComponent";
import TwoStepVerificationPhoneNumVerify from "./TwoStepVerificationPhoneNumVerify";
import toast, { Toaster } from "react-hot-toast";
import { TwoFactorProvider, UserPhoneNumber } from "../../swagger";
import Loading from "../../components/loading";
import classNames from "classnames";
import NoticeBar from "../../components/NoticeBar";

type propType = {
  hasPassword: boolean;
};

const PhoneNumbers = (props: propType) => {
  const [step, setStep] = useState<number>(1);
  const [editPhoneNumber, setEditPhoneNumber] = useState<number | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<UserPhoneNumber>(null);
  const { phoneNumbers, isLoading, isError } = useUserPhoneNumbers();
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 768;
  useEffect(() => {
    setEditPhoneNumber(null);
  }, [phoneNumber]);

  return (
    <>
      <Toaster position="top-center" reverseOrder={true}></Toaster>
      {step == 1 ? (
        <div className="space-y-6  md:col-span-9 md:px-0">
          <div className={classNames("bg-white pt-6 overflow-hidden", {"rounded-[15px] shadow-[0_3px_5px_0_rgba(9,30,66,0.10)]":!isMobile})}>
            <div className="px-4 sm:px-6">
              <h2 className="text-lg font-medium leading-6 text-gray-900">
                {t("identity.phoneNumbers.phoneNumbers")}
              </h2>
            </div>
            <div className="mt-6 flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border-t border-gray-200">
                    <div className="p-6 grid grid-cols-1 gap-y-3">
                      <NoticeBar text={t("identity.phoneNumbers.notPublicPhoneNumbers")} />
                      <p className="text-md font-medium leading-6 text-gray-900">
                        {t("identity.phoneNumbers.phoneNumberYouveAdded")}
                      </p>
                      {phoneNumbers && !_.isEmpty(phoneNumbers)
                        ? phoneNumbers.map((p) => (
                            <PhoneComponent
                              setEditPhoneNumber={(id: number) => {
                                setEditPhoneNumber(id);
                                setStep(2);
                              }}
                              phone={p}
                            />
                          ))
                        : null}
                      <p className="text-sm break-words text-gray-600">
                        {t(
                          "identity.phoneNumbers.YourPhoneNumberHelpsUsKeepOurAccountSecureByAddingAnAdditionalLayer"
                        )}{" "}
                        <span className="underline cursor-pointer text-blue-600">
                          {t("identity.phoneNumbers.learnMore")}
                        </span>
                      </p>
                      <button
                        onClick={() => {
                          setEditPhoneNumber(null);
                          setStep(2);
                        }}
                        type="button"
                        className="w-fit h-fit py-2 px-10 inline-flex justify-center rounded-md bg-[#1A5DBC]  text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                      >
                        <PlusIcon className="-ml-0.5 h-5 w-5 text-white" />
                        {t("identity.phoneNumbers.AddPhoneNumber")}
                      </button>

                      {phoneNumbers ? <></> : <Loading />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : step == 2 ? (
        <NewPhoneForm
          hasPassword={props.hasPassword}
          setStep={setStep}
          phoneNumber={
            editPhoneNumber
              ? phoneNumbers.filter((p) => p.id == editPhoneNumber)[0]
              : null
          }
          setPhoneNumber={setPhoneNumber}
          editPhoneNumber={editPhoneNumber}
          isEdit={editPhoneNumber != null}
        />
      ) : step == 3 ? (
        <TwoStepVerificationPhoneNumVerify
          twoFactorProvider={TwoFactorProvider.PhoneNumber}
          twoStep={false}
          setStep={setStep}
          step={step}
          phoneNumber={phoneNumber}
          setTwoFactorEnabled={() => {}}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default PhoneNumbers;
