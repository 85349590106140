import React, { Fragment, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import toast, { Toaster } from "react-hot-toast";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Tabs from "./Tabs";
import ConfirmCode from "./ConfirmCode";
import ChangePass from "./ChangePass";

const MainPage = () => {
  const { t } = useTranslation("identity.");
  const [searchParams] = useSearchParams();
  const initialEmail = useMemo(() => searchParams.get('email'), [searchParams]);
  const [email , setEmail] = useState(initialEmail)
  const [isEmail , setIsEmail] = useState(false)
  const [captcha , setCaptcha] = useState("")
  const [step, setStep] = useState(1);
  const [userPhoneNumber, setUserPhoneNumber] = useState({});
  const [token, setToken] = useState("");
  return (
    <>
      <Toaster position="top-center" reverseOrder={true}></Toaster>
      <div className="mx-auto grid place-items-center min-h-screen  overflow-x-hidden">
        <div className="organizer clearfix registerFormContainer rounded overflow-visible lg:shadow-lg p-6">
          <div className="sizeHandler">
            <div className="signTop organizer clearfix">
              <div className="flex gap-x-6 place-items-center organizer loginForm fln m0a grid-6 grid-t-12">
                <div className="w-full px-8 registerImage sm:block hidden border-r-2 border-grey-50 leftFloat centerFlex halfColumn">
                  <div className="centerKeeper organizer">
                    <img src="/img/sign.svg" className="h-80" />
                  </div>
                </div>
                <div className="w-full rightFloat halfColumn leftBorder hv col-auto">
                  <div className="centerKeeper 2xl:w-[400px] xl:w-[350px] textInCenter organizer flex flex-col px-8 gap-x-6 gap-y-2 text-center font-semibold">
                    <Link to="/">
                      <a>
                        <img
                          src="/img/Voxdash.svg"
                          alt="VoxDash"
                          className="mx-auto h-12 w-150 flex justify-center"
                        />
                      </a>
                    </Link>
                    {step == 1 ? (
                      <Tabs  setCaptcha ={setCaptcha}  setEmail={setEmail} email={email} setIsEmail={setIsEmail} setStep={setStep} setUserPhoneNumber = {setUserPhoneNumber} />
                    ) : step == 2 ? (
                      <ConfirmCode captcha={captcha} setToken={setToken} isEmail = {isEmail} email={email} setStep={setStep}  userPhoneNumber={userPhoneNumber} />
                    ) : step == 3 ? (
                      <ChangePass isEmail = {isEmail} email={email} userPhoneNumber={userPhoneNumber} token = {token} />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainPage;
