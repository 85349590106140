import axios, { AxiosRequestConfig } from "axios";
import { Promise } from "es6-promise";
import { AccountClient } from "../swagger";
import qs from "qs";

export class TokenStorage {
  private static readonly LOCAL_STORAGE_TOKEN = "token";
  private static readonly LOCAL_STORAGE_REFRESH_TOKEN = "refresh_token";

  public static isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  public static getAuthentication(authObj: any): AxiosRequestConfig {
    return {
      headers: {
        Authorization:
          "Bearer " + (authObj ? authObj.access_token : this.getToken()),
      },
    };
  }

  public static getNewToken(authObj: any): Promise<any> {
    return new Promise((resolve, reject) => {
      var token = authObj ? authObj.refresh_token : this.getRefreshToken();

      // const body = {
      //     refresh_token: authObj ? authObj.refresh_token: this.getRefreshToken(),
      //     grant_type: "refresh_token"
      // };
      axios({
        method: "post",
        url: "https://api.petroidm.com/api/account/SignIn",
        data: qs.stringify({
          client_id: "petroIDM-1",
          client_secret:
            "dce62195291144d7bb188680e191c58763fc29a47ec443d83f20aa113bdd38310508e182e434bd58c9472a2cf925d9b",
          grant_type: "refresh_token",
          refresh_token: token,
        }),
        headers: {
          "content-type": "application/x-www-form-urlencoded;charset=utf-8",
        },
      })
        .then((response) => {
          if (response.data.IsFailure) {
            reject(response.data.Error);
            return;
          }
          if (!authObj) {
            // check if client side
            this.storeToken(response.data.access_token);
            this.storeRefreshToken(response.data.refresh_token);
          }

          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
      // var client = new AccountClient();
      // client.token('refresh_token', null, null, token, null, 'webapp', null)
      //   .then(response => {
      //     if(response.isFailure){
      //       reject(response.error)
      //       return
      //     }
      //     this.storeToken(response.value.access_token);
      //     this.storeRefreshToken(response.value.refresh_token);

      //     resolve(response.value.access_token);
      //   })
      //   .catch((error) => {
      //     reject(error);
      //   });
    });
  }

  public static storeToken(token: string): void {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
  }

  public static storeRefreshToken(refreshToken: string): void {
    localStorage.setItem(
      TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN,
      refreshToken
    );
  }

  public static clear(): void {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  public static getRefreshToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  private static getToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
  }
}
