import useSWR from "swr";
import { axiosInstance } from "../services/AxiosInstance";
import {AccountClient, UserinfoClient } from "../swagger";

function useUserInfo() {
    var client = new AccountClient(null, axiosInstance);
    const {data, error} = useSWR(`account.getUserInfo`, () => client.getUserInfo())
    return {
        user: data,
        isLoadingUserApi: !error && !data,
        isErrorUserApi: error
      }
}

export default useUserInfo;