import React from "react";

interface propsType {
  value?: any;
  onChange?: (v: boolean | undefined) => void;
  description?: string;
  isChecked?:boolean;
  title?:string;
}

const Main = (props: propsType) => {
  const { value, onChange, description , isChecked , title } = props;
  return (
    <div>
      <div className="relative flex items-start">
        <div className="flex h-5 items-center">
          <input
            id="comments"
            aria-describedby="comments-description"
            name="comments"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-primaryColor focus:ring-0 ring-white"
            value={value}
            checked={isChecked}
            onChange={(e) => {
              if (onChange) onChange(e.target.checked);
            }}
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="comments" className="font-medium text-black">
            {title}
          </label>
        </div>
      </div>
      {description?
        <div className="font-normal mx-1 ml-7  text-sm text-gray-400">
          {description}
        </div>
      :null}
    </div>
  );
};

export default Main;
