import { Form, Formik } from "formik";
import _ from "lodash";
import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../services/AxiosInstance";
import { AccountClient, TwoFactorProvider } from "../../swagger";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import CheckBox from '../Checkbox'
type propType = {
  setStep: Function;
  email: string;
  redirectUrl: string;
  setProvider:(provider:TwoFactorProvider)=> void;
};

const Password = (props: propType) => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [trustDevice , setTrustDevice] = useState(false)
  const { t } = useTranslation("identity.");
  

  const onSubmit = async (values) => {
    setLoading(true);
    const client = new AccountClient(undefined, axiosInstance);

    try {
      const res = await client.login(
        props.email,
        values.password,
        trustDevice,
        props.redirectUrl,
        null
      );
      if(!res.succeeded && res.key == 1){
        toast.error(_.first(res.errors) as any);
        props.setStep(6)
      }else{
        if (res.is2FAEnabled) {
          if (res?.succeeded) {
            props.setProvider(res.twoFactorProvider)
            props.setStep(5);
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(_.first(res.errors) as any);
          }
        } else {
          if (res?.succeeded) {
            toast.success(t("identity.src.components.login.Password.youreLoggedin"))
            if (props.redirectUrl && !_.isNull(props.redirectUrl)) {
              // console.log('redirect Url' , props.redirectUrl)
              window.location.replace(props.redirectUrl as string);
            } else {
              window.location.replace(process.env.REACT_APP_PUBLIC_URL);
            }
            setLoading(false);
          } else {
            setLoading(false);
            toast.error(_.first(res.errors) as any);
          }
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(t("identity.src.components.login.EnterEmail.somethingUnexpectedHappenedPleaseTryAgainLater"));
    }
  };

  const checkValidate = (values) => {
    let error = {} as any;
    if (values.password == "") {
      error.password = "Enter password";
    }
    return error;
  };

  return (
    <Fragment>
      <h1 className="text-xl normalDoubleBottomMargin text-gray-600">
        {t("identity.src.components.login.Password.WelcomeBack")}
      </h1>
      <h2 className="text-l normalDoubleBottomMargin text-gray-600">
        {props.email}
      </h2>

      <div
        onClick={() => props.setStep(1)}
        className="underline cursor-pointer inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
      >
        {t("identity.src.components.login.Password.UseADifferentEmail")}
      </div>
      <Formik
        onSubmit={onSubmit}
        initialValues={{ password: "" }}
        validate={checkValidate}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form
            onSubmit={handleSubmit}
            className="organizer  fln m0a grid-6 grid-t-12 "
          >
            <div className="flex flex-col gap-y-4 place-content-center ">
              <div>
                <div className="relative">
                  <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5">
                    <svg
                      fill="none"
                      onClick={() => setShowPassword(!showPassword)}
                      className={classnames("h-3 text-gray-700", {
                        hidden: !showPassword,
                        block: showPassword,
                      })}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z"
                      ></path>
                    </svg>

                    <svg
                      fill="none"
                      onClick={() => setShowPassword(!showPassword)}
                      className={classnames("h-3 text-gray-700", {
                        hidden: showPassword,
                        block: !showPassword,
                      })}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path
                        fill="currentColor"
                        d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z"
                      ></path>
                    </svg>
                  </div>
                  <input
                    value={values.password}
                    onChange={handleChange}
                    name="password"
                    type={showPassword ? "text" : "password"}
                    id="email-address-icon"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Password"
                  />

                </div>
                {errors.password ? (
                  <p className="text-red-500 text-xs italic">
                    {errors.password.toString()}
                  </p>
                ) : null}
              </div>
              <CheckBox 
                title="Trust this Device" 
                isChecked={trustDevice} 
                onChange={e => setTrustDevice(e)} 
                value={trustDevice} 
              />
              <button
                type="submit"
                className="text-white bg-[#00917c] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center block  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
              >
                {!loading ? (
                  <Fragment>
                    {t("identity.src.components.login.Password.submit")}
                    <svg
                      className="float-right flex w-5 h-5 ml-2 -mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </Fragment>
                ) : (
                  <Fragment>
                    <svg
                      role="status"
                      className="inline w-4 h-4 mr-3 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      />
                    </svg>
                    {t("identity.src.components.login.Password.Loading")}
                  </Fragment>
                )}
              </button>

              <Link to={"/account/ForgetPassword?email="+props.email}>
                <a className="underline inline-block align-baseline text-sm text-green-700 hover:text-green-800">
                  {t("identity.src.components.login.Password.ForgotPassword")}
                </a>
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};

export default Password;
